@import "_globals";


/*****************************************
***** SPEZIFIKATION
*****************************************/
.seminar-info-spezifikation-list{
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  margin-bottom: 15px;

  .seminar-spezification {
    border: 1px solid @c-lightgray-spezification;
    line-height: 17px;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 10px;
    text-transform: uppercase;

    &.neu {
      color: @c-orange-spezification;
    }

    &.zertifikat{
      color: @c-blue-spezification;
    }

    &.zertifikatslehrgang{
      color: @c-blue-spezification;
    }

    &.blendedlearning{
      color: @c-violet-spezification;
    }

    &.tagung{
      color: @c-lightblue-spezification;
    }

    &.workshop{
      color: @c-darkblue-spezification;
    }

    &.grundlagen{
      color: @c-green-spezification;
    }

  }
}

/*****************************************
***** HEADER
*****************************************/

.seminar-info-header{
  margin-bottom: 15px;

  .seminar-info-title-wrapper{

    .seminar-info-pretitle{
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .seminar-info-title{
      color: @c-primary-1;
      margin-bottom: 0px;
    }
    .seminar-info-subtitle {
      color: @c-primary-1;
    }
  }
}

/*****************************************
***** CONTENT
*****************************************/

.seminar-info-content{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .seminar-info-content-column-left {
    flex-basis: 65%;
  }

}


.list-view, .detail-view{
  >article {
    .infos-termine-wrapper {
      div.infos-termine {
        >ul {
          a.seminare-info-link {
            color: @c-darkgray-400;
            font-weight: normal;
            &:hover {
              color: @c-gray-300;
              text-decoration:underline;
            }
          }
        }
      }
    }
  }
}


/*****************************************
***** DETAIL SHOW
*****************************************/

.show-seminar-info-title{
  color: @c-primary-1;
}

.show-seminar-info-grey-acc{
  color: @c-darkgray-400;
}

@media screen and (max-width: 985px){
  .seminar-info-content{
    flex-direction: column;
    gap: 26px;
  }
}


