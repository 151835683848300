/*****************************************
***** SPEZIFIKATION
*****************************************/
.seminar-info-spezifikation-list {
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  margin-bottom: 15px;
}
.seminar-info-spezifikation-list .seminar-spezification {
  border: 1px solid #D2D2D2;
  line-height: 17px;
  font-size: 12px;
  font-weight: bold;
  padding: 2px 10px;
  text-transform: uppercase;
}
.seminar-info-spezifikation-list .seminar-spezification.neu {
  color: #CE9909;
}
.seminar-info-spezifikation-list .seminar-spezification.zertifikat {
  color: #1C67A8;
}
.seminar-info-spezifikation-list .seminar-spezification.zertifikatslehrgang {
  color: #1C67A8;
}
.seminar-info-spezifikation-list .seminar-spezification.blendedlearning {
  color: #85004D;
}
.seminar-info-spezifikation-list .seminar-spezification.tagung {
  color: #427E9F;
}
.seminar-info-spezifikation-list .seminar-spezification.workshop {
  color: #2C3F7E;
}
.seminar-info-spezifikation-list .seminar-spezification.grundlagen {
  color: #84B10A;
}
/*****************************************
***** HEADER
*****************************************/
.seminar-info-header {
  margin-bottom: 15px;
}
.seminar-info-header .seminar-info-title-wrapper .seminar-info-pretitle {
  margin-bottom: 5px;
  text-transform: uppercase;
}
.seminar-info-header .seminar-info-title-wrapper .seminar-info-title {
  color: #0080bc;
  margin-bottom: 0px;
}
.seminar-info-header .seminar-info-title-wrapper .seminar-info-subtitle {
  color: #0080bc;
}
/*****************************************
***** CONTENT
*****************************************/
.seminar-info-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.seminar-info-content .seminar-info-content-column-left {
  flex-basis: 65%;
}
.list-view > article .infos-termine-wrapper div.infos-termine > ul a.seminare-info-link,
.detail-view > article .infos-termine-wrapper div.infos-termine > ul a.seminare-info-link {
  color: #424242;
  font-weight: normal;
}
.list-view > article .infos-termine-wrapper div.infos-termine > ul a.seminare-info-link:hover,
.detail-view > article .infos-termine-wrapper div.infos-termine > ul a.seminare-info-link:hover {
  color: #7a7a7a;
  text-decoration: underline;
}
/*****************************************
***** DETAIL SHOW
*****************************************/
.show-seminar-info-title {
  color: #0080bc;
}
.show-seminar-info-grey-acc {
  color: #424242;
}
@media screen and (max-width: 985px) {
  .seminar-info-content {
    flex-direction: column;
    gap: 26px;
  }
}
